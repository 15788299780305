"use client";

import { MantineProvider } from "@mantine/core";
import React, { PropsWithChildren } from "react";

import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import { Notifications } from "@mantine/notifications";
import { isServer, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/sonner";

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

const Provider = ({ children }: PropsWithChildren) => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <MantineProvider forceColorScheme="dark" defaultColorScheme="dark">
        <Notifications position="top-right" zIndex={10000} />
        <Toaster />

        {children}
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default Provider;
